import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хянах самбар гэж юу вэ?",
  "description": null,
  "author": "OTC help",
  "category": "dashboard",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хянах самбар `}<strong parentName="h2">{`[Dashboard]`}</strong>{` гэж юу вэ?`}</h2>
    <p>{`Хянах самбар нь Биржийн бус зах зээлийн арилжаа болон зарлал мэдээллийг нийтэд мэдээлэх зорилго бүхий нэгдсэн самбар юм.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      